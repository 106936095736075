import React, { useEffect, useState } from "react";
import Banner from "../component/Banner";
import Layout from "../component/Layout/Layout";
import DevBlogsCard from "../component/DevBlogsCard";
import axios from "axios";

function DevBlogs() {
  let isActive = [];
  const [navbarData, setNavbarData] = useState("");
  let [blogItemName, setBlogItemName] = useState("All Post");

  useEffect(() => {
    const getBlogsTitleListData = async () => {
      const { data } = await axios.get(
        `https://ccgames.co.uk:3001/getblogscategories`
      );
      // console.log(data);
      setNavbarData(data);
    };
    getBlogsTitleListData();
  }, []);

  for (let i = 0; i <= navbarData.length; i++) {
    isActive.push(false);
  }
  console.log(isActive);

  const handleClick = (i, data) => {
    debugger;
    const element = document.querySelector("#blogSideBarUl");
    const children = element.children;
    setBlogItemName(data);
    for (let x = 0; x < isActive.length; x++) {
      if (i === x) {
        isActive[x] = true;
        children[`${i}`].classList.add("para_one", "fw-bold", "all_post_text");
      } else {
        isActive[x] = false;
        if (children[`${x}`].classList === "") {
          children[children[`${x}`]].classList = "";
        }
        children[`${x}`].classList.remove(
          "para_one",
          "fw-bold",
          "all_post_text"
        );
      }
    }
  };

  return (
    <Layout>
      <div className="container">
        <Banner title="Dev Blogs" />
        <div className="devBlogs_main">
          <div>
            {/* <p className="para_one fw-bold all_post_text">All Post</p> */}
            {/* <ul className="list-unstyled para_one devblogs_ul">{listItems}</ul> */}
            {navbarData.length > 0 && (
              <ul
                className="list-unstyled para_one devblogs_ul"
                id="blogSideBarUl"
              >
                <li className="para_one fw-bold all_post_text"
                  onClick={() => {
                    handleClick(0, "All Post");
                  }}
                >
                  All Posts
                </li>
                <>
                  {navbarData.map((d, index) => (
                    <>
                      <li
                        onClick={() => {
                          handleClick(index + 1, d.game_category_id);
                        }}
                      >
                        {d.game_category_name}
                      </li>
                    </>
                  ))}
                </>
              </ul>
            )}
          </div>
          <div>
            <DevBlogsCard parentToChild={blogItemName} />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default DevBlogs;
