import React, { useEffect, useState } from "react";
import Banner from "../component/Banner";
import Layout from "../component/Layout/Layout";
import Slider from '../component/Home/Slider'

import axios from "axios";

function About() {
	
  const [listOfCards, setlistOfCards] = useState([]);

  useEffect(() => {
    const getCardsData = async () => {
      const { data } = await axios.get("https://ccgames.co.uk:3001/getcards");
      setlistOfCards(data);
    };
    getCardsData();
  }, []);
	
  return (
    <Layout>
      <div className="container">
        <Banner title="About Us" />
        <p className="para_one text-center con-75 mb-5">
          CC Games was Founders in August 2018 by CrazyCorrs. CC Games was made
          to bring joy and fun gameplay to all Robloxains! <br /> <br />
          CrazyCorrs came out with his First Game in August 30th. The game was
          called "Zombie Killing Simulator" This game consisted of Killing
          Zombies and selling their blood which was used for Experiments by Dr
          Zues. <br /> <br />
          CrazyCorrs's First hit was Giant Dance Off Simulator which was
          released a 4 Months after ZKS on the 31st of January 2019. This game
          became a huge success very quickly gaining almost 15 Thousand
          concurrent players
        </p>
      </div>

      <div className="container">
        <hr className="hr_line" />
      </div>

    <section className='home_section1 py-5'>
        <div className="container">
          <div className="row text-white g-3">

          {listOfCards.map((data) => (
            <div className="col-md-4 position-relative text-center">
            <img src={`https://ccgames.co.uk:3001/${data.card_image}`} className="w-100" alt="" />
            <div className='home_section_overlay'>
              <h5>{data.card_heading_1} <br /> {data.card_heading_2}</h5>
              <p>{data.card_number_of_members}</p>
            </div>
          </div>
          ))}

          </div>
        </div>
      </section>
      {/* home section one end */}

      {/* home section two start */}
      <div className="section py-2 py-md-5">
        <div className="container">
          <h1 className='title_one text-center mb-3'>OUR Projects</h1>
          <Slider />
        </div>
      </div>
	  
    </Layout>
  );
}

export default About;
