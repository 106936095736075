import React, { useState, useEffect } from 'react';

export default function AdminHeader(props) {
  const [adminName, setAdminName] = useState('');
  const [adminImage, setAdminImage] = useState('');

  useEffect(() => {
	  const firstName = localStorage.getItem("firstName");
	  const lastName = localStorage.getItem("lastName");
	  const image = localStorage.getItem("image");

	  console.log("firstName:", firstName);
	  console.log("lastName:", lastName);
	  console.log("image:", image);

	  const fullName = `${firstName} ${lastName}`;
	  setAdminName(fullName);
	  setAdminImage(`https://ccgames.co.uk:3001/${image}`);
	}, []);

  return (
    <>
      <div className="admin_header">
        <div className="admin_header_inner">
          <h1>{props.title}</h1>
          <div className='d-flex gap-4 align-items-center'>
            <div>
                <p>Welcome</p>
                <h4 className='mt-1'>{adminName}</h4>
            </div>
            <img src={adminImage} className="adminImg" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}