import React, { useEffect, useState } from "react";
import editIcon from "../assets/images/icons/edit.svg";
import delIcon from "../assets/images/icons/delete.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function BlogCard() {
  const [listOfBlogs, setlistOfBlogs] = useState([]);

  useEffect(() => {
    const getBlogsData = async () => {
      try {
        const response = await axios.get(`https://ccgames.co.uk:3001/getadminblog/${localStorage.getItem('user_id')}`);
        const data = response.data;
        setlistOfBlogs(data);
        console.log(data);
        localStorage.setItem(`blogsLength`, data.length);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };
    getBlogsData();
  }, []);

  const deleteBlog = (id) => {
    console.log(id);
    debugger;

    axios.delete(`https://ccgames.co.uk:3001/deleteblog/${id}`).then((res) => {
      if (res.data.error) {
        toast.error(res.data.error, { 
          position: toast.POSITION.TOP_CENTER
        });
      } else {
        toast.success("Blog is deleted Successfully...", {
          position: toast.POSITION.TOP_CENTER
        });
        setTimeout(() => {
          window.location.reload();
         }, 2000);  
      }
    });
  };

  return (
    <>
      <ToastContainer />
      {listOfBlogs.length > 0 && listOfBlogs.map((data, i) => (
        <div className="blog_card" key={data.blog_id}>
          <img
            src={`https://ccgames.co.uk:3001/${data.thumbnail_image}`}
            className="blog_card_img"
            alt=""
          />
          <div>
            <h1 className="para_one fw-bold">{data.blog_title}</h1>
            <h4 className="para_two mt-2" style={{ color: "#8F8F8F" }}>
              {data.blog_thumbnail_description}
            </h4>
            <div className="dbl_btn mt-4">
              <Link to={`/updateblog/${data.blog_id}`} className="w-100">
                <button>
                  <img src={editIcon} alt="" /> Edit
                </button>
              </Link>
              <button onClick={() => deleteBlog(data.blog_id)}>
                <img src={delIcon} alt="" /> Delete
              </button>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default BlogCard;