import React, { Component } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import editIcon from "../assets/images/icons/edit.svg";
import delIcon from "../assets/images/icons/delete.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// button
import arrow from "../assets/images/icons/arrow-square-left.svg";
// button

class DashSlider extends Component {
  state = {
    slider: [],
  };

  componentDidMount() {
    fetch(`https://ccgames.co.uk:3001/getadminslider/${localStorage.getItem("user_id")}`)
      .then((response) => response.json())
      .then((sliderList) => {
        console.log(sliderList);
        this.setState({ slider: sliderList });
      });
  }

  deleteSlider = (id) => {
    console.log(id);
    axios.delete(`https://ccgames.co.uk:3001/deletemainslider/${id}`)
      .then((res) => {
        if (res.data.error) {
          toast.error(
            res.data.error,
            { position: toast.POSITION.TOP_CENTER, autoClose: 5000 }
          );
        } else {
          toast.success(
            "Slider Image is deleted Successfully...",
            { position: toast.POSITION.TOP_CENTER, autoClose: 5000 }
          );
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      });
  };

  renderItems() {
    return this.state.slider.map((data) => (
      <div className="item" data-value="1" key={data.main_slider_image_id}>
        <a href="#!" className="position-relative">
          <img
            src={`https://ccgames.co.uk:3001/${data.main_slider_image}`}
            className="dashImg"
            alt=""
          />
          <div className="das_slider_btn">
            <Link to={`/updatemainslider/${data.main_slider_image_id}`}>
              <button className="custom-file-upload">
                <img src={editIcon} alt="" /> Edit
              </button>
            </Link>
            <button
              className="mt-2"
              onClick={() => this.deleteSlider(data.main_slider_image_id)}
            >
              <img src={delIcon} alt="" /> Delete
            </button>
          </div>
        </a>
      </div>
    ));
  }

  renderNavItems() {
    return this.state.slider.map((_, i) => (
      <i key={i} onClick={() => this.carousel.slideTo(i)} />
    ));
  }

  render() {
    const responsive = {
      0: { items: 1 },
      568: { items: 1 },
      1024: { items: 2 },
    };

    return (
      <>
        <div className="d-flex align-items-center flex-wrap gap-2 gap-sm-4">
          <h4 className="dash_slider_title">
            Main Slider ({this.state.slider.length})
          </h4>
          <div className="d-flex align-items-center gap-4">
            <img
              src={arrow}
              onClick={() => this.carousel.slidePrev()}
              className="dash_slider_arrow"
              alt=""
            />
            <img
              src={arrow}
              onClick={() => this.carousel.slideNext()}
              className="dash_slider_arrow arrow_right"
              alt=""
            />
          </div>
          <div className="projectSlider"></div>
        </div>
        <div className="dashSlider mt-2 mt-sm-4">
          <AliceCarousel
            mouseTracking={false}
            disableDotsControls
            disableButtonsControls
            items={this.renderItems()}
            responsive={responsive}
            ref={(el) => (this.carousel = el)}
          />
          <nav>{this.renderNavItems()}</nav>
        </div>
      </>
    );
  }
}

export default DashSlider;