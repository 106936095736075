import React, { useState, useEffect } from "react";
import AdminLayout from "../component/AdminLayout/AdminLayout";
import axios from "axios";
import gallary from "../assets/images/icons/gallery.svg";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UpdateMainSlider() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [game_category_id, setGameCategoryId] = useState("");
  const [game_category_name, setGameCategoryName] = useState("");
  const [game_id, setGameId] = useState("");
  const [main_slider_image, setMainSliderImage] = useState("");
  const [main_slider_image_path, setMainSliderImagePath] = useState("");

  useEffect(() => {
    const getDataById = async () => {
      const { data } = await axios.get(
        `https://ccgames.co.uk:3001/getsinglemainslider/${id}`
      );
      let slider_image_path = data.main_slider_image;
      slider_image_path = slider_image_path.slice(7);
      console.log(slider_image_path)
      setMainSliderImage(data.main_slider_image);
      setMainSliderImagePath(slider_image_path);
      setGameCategoryId(data.game_category_id);

      let slider_image = document.getElementById("slider_img");
      slider_image.src = `https://ccgames.co.uk:3001/${data.main_slider_image}`;

      var new_id = data.game_category_id;
      getGameData(new_id);
    };

    const getGameData = async (id) => {
      const { data } = await axios.get(
        `https://ccgames.co.uk:3001/getsingleblogcategoryusingid/${id}`
      );
      console.log(data);
      setGameCategoryName(data.game_category_name);
      setGameId(data.game_id);
    };

    getDataById();
  }, [id]);

  const updateSlider = async (e) => {
    // console.log(typeof(main_slider_image));

    debugger;

    let formData = {};
    if (typeof main_slider_image === "string") {
      formData["main_slider_image"] = main_slider_image;
    } else {
      formData = new FormData();
      formData.append("main_slider_image", main_slider_image);
      formData.append("main_slider_image_path", main_slider_image_path);
      formData.append("game_category_id", game_category_id);
    }

    e.preventDefault();
    axios
      .put(`https://ccgames.co.uk:3001/updatemainslider/${id}`, formData)
      .then((res) => {
        if (res.data.error) {
          toast.error(
            res.data.error,
            {
              position: toast.POSITION.TOP_CENTER,
            },
            { autoClose: 5000 }
          );
        } else {
          toast.success("Slider is updated Successfully...", {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            navigate("/dashboard");
            window.location.reload();
          }, 2000);
        }
      });
  };

  return (
    <>
      <AdminLayout lefttitle="add blog">
        <form action="">
          <div className="admin_div blog_div1 d-block">
            <div className="d-flex justify-content-center">
              <div className="child child1">
                <div className="input_file_area d-flex justify-content-center">
                  <input
                    type="file"
                    name=""
                    id=""
                    onChange={(e) => {
                      setMainSliderImage(e.target.files[0]);
                      let sliderImg = document.getElementById("slider_img");
                      sliderImg.src = URL.createObjectURL(e.target.files[0]);
                    }}
                  />
                  <div className="input_image_main">
                    <img
                      src={gallary}
                      alt=""
                      className="input_file_img img-fluid"
                      id="slider_img"
                    />
                    <h5 className="input_desc mt-2 mt-md-3">
                      Update Slider <br /> Images
                    </h5>
                    <p className="input_file_im_text mt-2 mt-md-4">
                      Minimum Size 270 x 200px
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <input
                  type="text"
                  className="d-block input_field_c w-100 border px-4"
                  value={game_id}
                  disabled={true}
                  placeholder="Enter Game Id"
                  onChange={(event) => {
                    setGameId(event.target.value);
                  }}
                />
                <p className="pt-2 pb-4 ps-3">
                  <span style={{ color: "red" }}>*</span>Required
                </p>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="input_field_c w-100 border px-4"
                  value={game_category_name}
                  disabled={true}
                  placeholder="Enter Game Category Name"
                  onChange={(event) => {
                    setGameCategoryName(event.target.value);
                  }}
                />
                <p className="pt-2 pb-4 ps-3">
                  <span style={{ color: "red" }}>*</span>Required
                </p>
              </div>
            </div>
            <div className="text-center text-md-end mt-4 mt-md-5">
              <input
                type="submit"
                value="Update"
                className="btn_one py-2 px-3 py-md-3 px-md-5 btn_w_100"
                onClick={updateSlider}
              />
              <ToastContainer />
            </div>
          </div>
        </form>
      </AdminLayout>
    </>
  );
}

export default UpdateMainSlider;
