import React, { useEffect, useState } from "react";
import AdminLayout from "../component/AdminLayout/AdminLayout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function BlogCategory() {
  const navigate = useNavigate();

  const pathname = window.location.pathname;
  console.log(pathname);

  const [game_category_name, setGameCategoryName] = useState("");
  const [game_id, setGameId] = useState("");

  useEffect(() => {
    debugger;
    if (pathname === "/postblogCategory") {
      setGameId(sessionStorage.getItem("game_id"));
    } else {
      setGameId(sessionStorage.getItem("slider_game_id"));
    }
  },  [pathname]);

  const postBlogCategories = (e) => {
    debugger;
    const data = {
      game_id: game_id,
      game_category_name: game_category_name,
    };
    e.preventDefault();
    if (pathname === "/postblogCategory") {
      axios
        .post("https://ccgames.co.uk:3001/postblogscategories", data)
        .then((res) => {
          if (res.data.error) {
            toast.error(res.data.error, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success("Blog is posted Successfully...", {
              position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
              navigate("/addblog");
            }, 2000);
            sessionStorage.setItem(`game_id`, game_id);
          }
        });
    }
    else {
      axios
        .post("https://ccgames.co.uk:3001/postslidercategories", data)
        .then((res) => {
          if (res.data.error) {
            toast.error(res.data.error, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success("Slider Category is posted Successfully...", {
              position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
              navigate("/postmainslider");
            }, 2000);
            sessionStorage.setItem(`slider_game_id`, game_id);
          }
        });
    }
  };

  return (
    <>
      <AdminLayout lefttitle="Add Blog Category">
        <form action="">
          <div className="admin_div blog_div1">
            <div className="child child3">
              <input
                type="text"
                className="d-block input_field_c w-100 border px-4"
                value={game_id}
                placeholder="Enter Game ID"
                onChange={(event) => {
                  setGameId(event.target.value);
                }}
              />
              <p className="pt-2 pb-4 ps-3">
                <span style={{ color: "red" }}>*</span>Required
              </p>
              <input
                type="text"
                className="d-block input_field_c w-100 border px-4"
                placeholder="Enter Game Category Name"
                onChange={(event) => {
                  setGameCategoryName(event.target.value);
                }}
              />
              <p className="pt-2 pb-4 ps-3">
                <span style={{ color: "red" }}>*</span>Required
              </p>
              <div className="text-center text-md-end mt-2 mt-md-3">
                <button
                  className="btn_one py-2 px-3 py-md-3 px-md-5 btn_w_100"
                  onClick={postBlogCategories}
                >
                  Add
                </button>
                <ToastContainer />
              </div>
            </div>
          </div>
        </form>
      </AdminLayout>
    </>
  );
}

export default BlogCategory;
