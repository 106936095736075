import React, { useState } from "react";
import AdminLayout from "../component/AdminLayout/AdminLayout";
import lock from "../assets/images/icons/lock.svg";
import eye from "../assets/images/icons/eye.svg";
import { TickSqure } from "../component/Icon/TickSqure";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ChangePassword() {

  const navigate = useNavigate();

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  let updatePassword = null;

  if (
    newPassword === confirmPassword &&
    newPassword.length >= 6 &&
    /[A-Z]/.test(newPassword) &&
    /[a-z]/.test(newPassword) &&
    /[1-9]/.test(newPassword)
  ) {
    updatePassword = () => {
      axios
        .put(
          `https://ccgames.co.uk:3001/updatepassword/${localStorage.getItem(
            "user_id"
          )}`,
          {
            oldPassword: oldPassword,
            newPassword: newPassword,
          },
          {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          }
        )
        .then((response) => {
          if (response.data.error) {
            toast.error(response.data.error, {
              position: toast.POSITION.TOP_CENTER
            });
          } else {
              toast.success("Password Updated Successfully...", {
                position: toast.POSITION.TOP_CENTER
              });
              setTimeout(() => {
                navigate("/dashboard");
               }, 2000); 
          }
        });
    };
  }

  const showPasswordHandler = (trueCondition, elementId, passwordMethod) => {
    if (trueCondition) {
      document.getElementById(elementId).type = "password";
      passwordMethod(false);
    } else {
      document.getElementById(elementId).type = "text";
      passwordMethod(true);
    }
  };

  return (
    <AdminLayout lefttitle="Change Password">
      <div className="admin_div">
        <h1 className="para_one fw-bold mb-3">Enter Current Password</h1>

        <div className="col-md-6">
          <div className="position-relative">
            <input
              type="password"
              id={"oldPassword"}
              className="input_field_c w-100 border"
              placeholder="Enter Current Password"
              onChange={(event) => {
                setOldPassword(event.target.value);
              }}
            />
            <img src={lock} className="input_icon_left" alt="" />
            <button
              className="input_right_icon"
              onClick={() =>
                showPasswordHandler(
                  showOldPassword,
                  "oldPassword",
                  setShowOldPassword
                )
              }
            >
              {showOldPassword === false ? (
                <>
                  <img src={eye} className="cursor-pointer" alt="" />
                </>
              ) : (
                <>
                  <img src={eye} className="cursor-pointer" alt="" />
                  <div className="close_line1"></div>
                </>
              )}
            </button>
          </div>
        </div>

        <hr className="horizontal_line" />

        <h1 className="para_one fw-bold mb-3">Enter New Password</h1>
        <div className="row g-4">
          <div className="col-md-6">
            <div className="position-relative">
              <input
                type="password"
                id="newPassword"
                className="input_field_c w-100 border"
                placeholder="Enter New Password"
                onChange={(event) => {
                  setNewPassword(event.target.value);
                  console.log(newPassword);
                }}
              />
              <img src={lock} className="input_icon_left" alt="" />
              <button
                className="input_right_icon"
                onClick={() =>
                  showPasswordHandler(
                    showNewPassword,
                    "newPassword",
                    setShowNewPassword
                  )
                }
              >
                {showNewPassword === false ? (
                  <>
                    <img src={eye} className="cursor-pointer" alt="" />
                  </>
                ) : (
                  <>
                    <img src={eye} className="cursor-pointer" alt="" />
                    <div className="close_line1"></div>
                  </>
                )}
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="position-relative">
              <input
                type="password"
                id={"confirmPassword"}
                className="input_field_c w-100 border"
                placeholder="Confirm New Password"
                onChange={(event) => {
                  setConfirmPassword(event.target.value);
                }}
              />
              <img src={lock} className="input_icon_left" alt="" />
              <button
                className="input_right_icon"
                onClick={() =>
                  showPasswordHandler(
                    showConfirmPassword,
                    "confirmPassword",
                    setShowConfirmPassword
                  )
                }
              >
                {showConfirmPassword === false ? (
                  <>
                    <img src={eye} className="cursor-pointer" alt="" />
                  </>
                ) : (
                  <>
                    <img src={eye} className="cursor-pointer" alt="" />
                    <div className="close_line1"></div>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-6">
            <h5 className="para_two text-dark-grey">Password must contain :</h5>
            <ul className="list-unstyled changepass_ul">
              <li
                className={
                  newPassword === confirmPassword && newPassword.length >= 6
                    ? "cp_matching_item"
                    : ""
                }
              >
                <TickSqure />
                Atleast 6 Character
              </li>
              <li
                className={
                  newPassword === confirmPassword && /[A-Z]/.test(newPassword)
                    ? "cp_matching_item"
                    : ""
                }
              >
                <TickSqure />
                Atleast 1 Uppercase Letter (A..Z)
              </li>
              <li
                className={
                  newPassword === confirmPassword && /[a-z]/.test(newPassword)
                    ? "cp_matching_item"
                    : ""
                }
              >
                <TickSqure />
                Atleast 1 Lowercase Letter (a..z)
              </li>
              <li
                className={
                  newPassword === confirmPassword && /[1-9]/.test(newPassword)
                    ? "cp_matching_item"
                    : ""
                }
              >
                <TickSqure />
                Atleast 1 Number (1..0)
              </li>
            </ul>
          </div>
          <div className="col-md-6 d-flex justify-content-start justify-content-md-end align-items-start">
            <button
              className="btn_one py-2 px-3 py-md-3 px-md-5 btn_w_100"
              onClick={updatePassword}
            >
              Update
            </button>
            <ToastContainer />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default ChangePassword;
