import React from "react";
import AdminLayout from "../component/AdminLayout/AdminLayout";
import gallary from "../assets/images/icons/gallery.svg";
import DashSlider from "../component/DashSlider";
import BlogCard from "../component/BlogCard";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

function DashBoard() {
  const navigate = useNavigate();
  let blogsLength = localStorage.getItem("blogsLength");

  const mainSliderAdd = () => {
    navigate('/postmainslider')
  }

  // Add error logging for blogsLength
  if (!blogsLength) {
    console.error("Error: blogsLength is undefined or null");
  }

  return (
    <>
      <AdminLayout lefttitle="dashboard">
        <div className="dash_div1 admin_div">
          {/* child1 */}
          <div className="input_file_area">
            <div className="input_image_main" onClick={mainSliderAdd}>
              <img
                src={gallary}
                alt=""
                id="slider_img"
                className="input_file_img img-fluid"
              />
              <h5 className="input_desc mt-2 mt-md-4">
                Add Slider <br />
                Images
              </h5>
              <p className="input_file_im_text mt-3 mt-md-5">
                Minimum Size <br />
                1000 x 600px
              </p>
            </div>
          </div>
          {/* child1 */}

          {/* child2 */}
          <div className="dash_div1_child2">
            <DashSlider />
          </div>
          {/* child2 */}
        </div>

        <div className="dash_div2 admin_div">
          <div className="dash_div2_header">
            <h4 className="dash_slider_title">Blogs ({blogsLength})</h4>
            <Link to="/addblog">
              <button className="btn_one px-4">Add Blog</button>
            </Link>
          </div>
          <BlogCard />
        </div>
      </AdminLayout>
    </>
  );
}

export default DashBoard;