import React, { useEffect, useState } from "react";
import AdminLayout from "../component/AdminLayout/AdminLayout";
import axios from "axios";
import gallary from "../assets/images/icons/gallery.svg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function MainSliderAdd() {
  const navigate = useNavigate();

  const [game_category_id, setGameCategoryId] = useState("");
  const [game_category_name, setGameCategoryName] = useState("");
  const [game_id, setGameId] = useState("");
  const [frontend_game_id, setFrontGameId] = useState("");
  const [main_slider_image, setMainSliderImage] = useState("");

  useEffect(() => {
    const getBlogsData = async () => {
      if (sessionStorage.getItem("slider_game_id") !== null) {
        const { data } = await axios.get(
          `https://ccgames.co.uk:3001/getsingleslidercategory/${sessionStorage.getItem(
            "slider_game_id"
          )}`
        );
        if (data) {
          setGameCategoryId(data.slider_game_category_id);
          setGameCategoryName(data.slider_game_category_name);
          setGameId(data.slider_game_id);
        }
      }
    };
    getBlogsData();
  }, []);

  const mainSliderAdd = async (e) => {
    debugger;

    const formData = new FormData();

    formData.append("main_slider_image", main_slider_image);
    formData.append("game_category_id", game_category_id);
    formData.append("user_id", localStorage.getItem("user_id"));

    e.preventDefault();

    axios.post("https://ccgames.co.uk:3001/postmainslider", formData).then((res) => {
      if (res.data.error) {
        toast.error(res.data.error, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.success("Main Slider is posted Successfully...", {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          navigate("/dashboard");
          window.location.reload();
        }, 2000);
        sessionStorage.removeItem("slider_game_id");
      }
    });
  };

  const search = async (key) => {
    const game_id_pattern = /^[0-9]*$/;

    if (game_id_pattern.test(key)) {
      const { data } = await axios.get(
        `https://ccgames.co.uk:3001/getsingleslidercategory/${key}`
      );
      if (data) {
        setGameCategoryId(data.slider_game_category_id);
        setGameId(data.slider_game_id);
        setGameCategoryName(data.slider_game_category_name);
        toast.success(
          data.slider_game_id +
            " " +
            " - " +
            data.slider_game_category_name +
            " " +
            "successfully found",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      } else {
        debugger;
        toast.error("Slider Game Category is not found!!! ", {
          position: toast.POSITION.TOP_CENTER,
        });
        sessionStorage.setItem(`slider_game_id`, key);
        setTimeout(() => {
          navigate("/postsliderCategory");
        }, 2000);
      }
    } else {
      toast.error("Kindly enter Numeric Id!!!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <>
      <AdminLayout lefttitle="add main slider">
        <form action="">
          <div className="admin_div blog_div1 d-block clearfix">
            <div className="d-flex justify-content-center">
              <div className="child child1">
                <div
                  className={
                    game_id === ""
                      ? "d-none"
                      : "d-block input_file_area d-flex justify-content-center"
                  }
                >
                  <input
                    type="file"
                    name=""
                    id=""
                    onChange={(e) => {
                      let sliderImg = document.getElementById("slider_img");
                      sliderImg.src = URL.createObjectURL(e.target.files[0]);
                      setMainSliderImage(e.target.files[0]);
                    }}
                  />
                  <div className="input_image_main">
                    <img
                      src={gallary}
                      alt=""
                      id="slider_img"
                      className="input_file_img img-fluid"
                    />
                    <h5 className="input_desc mt-2 mt-md-4">
                      Add Slider <br />
                      Images
                    </h5>
                    <p className="input_file_im_text mt-3 mt-md-5">
                      Minimum Size <br />
                      1000 x 600px
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="child child3 mt-3 clearfix">
              <input
                type="text"
                className={
                  game_id !== ""
                    ? "d-none"
                    : "d-block input_field_c w-100 border px-4"
                }
                placeholder="Search Game Id"
                onChange={(event) => {
                  setFrontGameId(parseInt(event.target.value));
                }}
              />
              <p className={game_id !== "" ? "d-none" : "d-block pt-2 ps-3"}>
                <span style={{ color: "red" }}>*</span>Required
              </p>
              <button
                type="button"
                class={
                  game_id !== ""
                    ? "d-none"
                    : "d-block btn btn-primary my-2 me-4 float-end"
                }
                onClick={() => {
                  search(frontend_game_id);
                }}
              >
                Search
              </button>
              <div className="row">
                <div className="col-md-6">
                  <input
                    type="text"
                    className={
                      game_id === ""
                        ? "d-none"
                        : "d-block input_field_c w-100 border px-4"
                    }
                    value={game_id}
                    disabled={true}
                    placeholder="Enter Game Id"
                    onChange={(event) => {
                      setGameId(event.target.value);
                    }}
                  />
                  <p
                    className={
                      game_id === "" ? "d-none" : "d-block pt-2 pb-4 ps-3"
                    }
                  >
                    <span style={{ color: "red" }}>*</span>Required
                  </p>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    className={
                      game_id === ""
                        ? "d-none"
                        : "d-block input_field_c w-100 border px-4"
                    }
                    value={game_category_name}
                    disabled={true}
                    placeholder="Enter Game Category Name"
                    onChange={(event) => {
                      setGameCategoryName(event.target.value);
                    }}
                  />
                  <p
                    className={
                      game_id === "" ? "d-none" : "d-block pt-2 pb-4 ps-3"
                    }
                  >
                    <span style={{ color: "red" }}>*</span>Required
                  </p>
                </div>
              </div>
            </div>

            <button
              className={
                game_id === ""
                  ? "d-none"
                  : "d-block btn_one py-2 px-3 py-md-3 px-md-5 btn_w_100 mx-md-0 mx-auto float-md-end"
              }
              onClick={mainSliderAdd}
            >
              Add
            </button>
            <ToastContainer />
          </div>
        </form>
      </AdminLayout>
    </>
  );
}

export default MainSliderAdd;
