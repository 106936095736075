import React from "react";
import { useNavigate } from "react-router-dom";

function PageNotFound() {

  const navigate = useNavigate();
  
  const goToHome = () => {
    navigate('/')
  }

  return (
    <div id="notfound">
		<div class="notfound">
			<div class="notfound-404">
				<h1>Oops!</h1>
			</div>
			<h2>404 - Page not found</h2>
			<p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
			<button onClick={goToHome}>Go To Homepage</button>
		</div>
	</div>
  );
}

export default PageNotFound;