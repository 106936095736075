import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "../About";
import AccountSetting from "../AccountSetting";
import Blog from "../Blog";
import UpdateBlog from "../UpdateBlog";
import ChangePassword from "../ChangePassword";
import DashBoard from "../DashBoard";
import DevBlogDetails from "../DevBlogDetails";
import DevBlogs from "../DevBlogs";
import UpdateMainSlider from "../UpdateMainSlider";
import Home from "../Home";
import Login from "../Login";
import Merch from "../Merch";
import ProtectedRoutes from "../../component/ProtectedRoutes";
import PageNotFound from "../PageNotFound";
import BlogCategory from "../BlogCategory";
import MainSliderAdd from "../MainSliderAdd";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/merch" element={<Merch />} />
        <Route path="/devblogs" element={<DevBlogs />} />
        <Route path="devblogsDetails/:id" element={<DevBlogDetails />} />
        <Route path="/*" element={<PageNotFound />} />

        {/* admin route */}
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<ProtectedRoutes Component={DashBoard} />} />
        <Route path="/addblog" element={<ProtectedRoutes Component={Blog}/>} />
        <Route path="/updateblog/:id" element={<ProtectedRoutes Component={UpdateBlog}/>} />
        <Route path="/changepassword" element={<ProtectedRoutes Component={ChangePassword}/>} />
        <Route path="/settings" element={<ProtectedRoutes Component={AccountSetting}/>} />
        <Route path="/postmainslider" element={<ProtectedRoutes Component={MainSliderAdd}/>} />
        <Route path="/updatemainslider/:id" element={<ProtectedRoutes Component={UpdateMainSlider}/>} />
        <Route path="/postblogCategory" element={<ProtectedRoutes Component={BlogCategory}/>} />
        <Route path="/postsliderCategory" element={<ProtectedRoutes Component={BlogCategory}/>} />
        {/* admin route */}

      </Routes>
    </BrowserRouter>
  );
}

export default Router;
