import Router from "./pages/Router/Router";
import './assets/css/style.css'
import './assets/css/responsive.css'

function App() {
  return (
    <>
      <Router />
    </>
  );
}
export default App;
